import React from "react";
import { AppBar } from "@material-ui/core";

import { Barcode, BarcodeResult } from "scanbot-web-sdk/@types";

import { NavigationContent } from "./subviews/navigation-content";
import { Toast } from "./subviews/toast";
import FeatureList from "./subviews/feature-list";
import { BottomBar } from "./subviews/bottom-bar";

import Pages from "./model/pages";
import { ScanbotSdkService } from "./service/scanbot-sdk-service";
import { RoutePath, RoutingService } from "./service/routing-service";

import { NavigationUtils } from "./utils/navigation-utils";
import { AnimationType } from "./rtu-ui/enum/animation-type";
import BarcodeScannerComponent from "./rtu-ui/barcode-scanner-component";
import Barcodes from "./model/barcodes";
import ErrorLabel from "./subviews/error-label";


export default class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      alert: undefined,
      activeImage: undefined,
      sdk: undefined,
      error: {
        message: undefined,
      },
    };
  }

  async componentDidMount() {
    let sdk = await ScanbotSdkService.instance.initialize();
    this.setState({ sdk: sdk });
    this.forceUpdate();

    RoutingService.instance.observeChanges(() => {
      this.forceUpdate();
    });

    await ScanbotSdkService.instance.setLicenseFailureHandler(async (error: any) => {
      sdk = await ScanbotSdkService.instance.initialize();
      this.setState({ sdk: sdk });
      this.forceUpdate();
    });
  }

  onBackPress() {
    RoutingService.instance.back();
  }

  navigation?: any;

  toolbarHeight() {
    return (this.navigation as HTMLHeadingElement)?.clientHeight ?? 0;
  }

  containerHeight() {
    if (!this.navigation) {
      return "100%";
    }
    return window.innerHeight - 2 * this.toolbarHeight() ?? 0;
  }

  render() {
    return (
      <div>
        {this.barcodeScanner()}

        <Toast
          alert={this.state.alert}
          onClose={() => this.setState({ alert: undefined })}
        />

        <AppBar
          position="fixed"
          ref={(ref) => (this.navigation = ref)}
          style={{ zIndex: 19 }}
        >
          <NavigationContent
            backVisible={!NavigationUtils.isAtRoot()}
            onBackClick={() => this.onBackPress()}
          />
        </AppBar>
        <div
          style={{
            height: this.containerHeight(),
            marginTop: this.toolbarHeight(),
          }}
        >
          {this.decideContent()}
        </div>
        <BottomBar
          hidden={NavigationUtils.isAtRoot()}
          height={this.toolbarHeight()}
        />
      </div>
    );
  }

  _barcodeScannerHtmlComponent: any;
  _barcodeScanner?: BarcodeScannerComponent | null;
  barcodeScanner() {
    if (!this._barcodeScannerHtmlComponent) {
      this._barcodeScannerHtmlComponent = (
        <BarcodeScannerComponent
          ref={(ref) => (this._barcodeScanner = ref)}
          sdk={this.state.sdk}
          onBarcodesDetected={this.onBarcodesDetected.bind(this)}
        />
      );
    }
    return this._barcodeScannerHtmlComponent;
  }

  decideContent() {
    return (
      <div>
        <ErrorLabel message={this.state.error.message} />
        <FeatureList onItemClick={this.onFeatureClick.bind(this)} />
      </div>
    );
  }

  deletePage() {
    Pages.instance.removeActiveItem();
    RoutingService.instance.route(RoutePath.ImageResults);
  }

  async onBarcodesDetected(result: BarcodeResult) {
    Barcodes.instance.addAll(result.barcodes);
    console.log(result.barcodes);
    // If you have any additional processing to do, consider pausing
    // the scanner here, else you might (will) receive multiple results:
    // ScanbotSdkService.instance.barcodeScanner?.pauseDetection();
    // this.setState({
    //   alert: { color: "success", text: this.formatBarcodes(result.barcodes) },
    // });
    const code = result.barcodes[0].text
    console.log(code)
    const match = code.match(/01(04150[0-9]{9})/)
    if (match && match[1]) {
      const ntin = match[1]
      window.location.href = `https://rslv.valoop.co/01/${ntin}?linkType=scan`
      return
    }

    const match2 = code.match(/\(01\)\s(04150[0-9]{9})/)
    if (match2 && match2[1]) {
      const ntin = match2[1]
      window.location.href = `https://rslv.valoop.co/01/${ntin}?linkType=scan`
    }
  }

  formatBarcodes(codes: Barcode[]): string {
    return JSON.stringify(
      codes.map((code: Barcode) => code.text + " (" + code.format + ") ")
    );
  }

  async onFeatureClick(feature: any) {
    if (feature.id === RoutePath.BarcodeScanner) {
      this._barcodeScanner?.push(AnimationType.PushBottom);
      return;
    }
  }
}
