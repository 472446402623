import { RoutePath } from "../service/routing-service";

export class Features {
  public static LIST = [
    {
      id: RoutePath.BarcodeScanner,
      name: "Click to scan",
      route: RoutePath.BarcodeScanner,
    },
  ];
}
